import Head from 'next/head';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import ErrorPage from './_error';
import PageHome from '../components/Pages/PageHome';
import {
  ServerSideRenderingDataContext,
  ServerSideRenderingDataInterface,
  useServerSideRenderingDataContext
} from '../components/ServerSideRenderingDataContext';
import {
  query404Page,
  queryBlockAccordions,
  queryBlocks,
  queryHomePage,
  queryLayout,
  querySiteNavigationModule
} from '../server/ssrqueries';
import { getApolloClientInstance } from '../server/ssrapollo';
import { ssrQueriesDispatch } from '../server/ssrQueries/dispatch';
import { generateCanonicalTag } from '../helpers/generateCanonicalTag';
import React from 'react';
import { formatNavigationModule } from '../server/navigation';

interface Props {
  queryResults: ServerSideRenderingDataInterface;
}

const HomePage = () => {
  const dataContext = useServerSideRenderingDataContext();
  const { data, errors, loading } = dataContext.HOMEPAGE_QUERY;

  if (loading) return null;
  if (errors) return <ErrorPage statusCode={500} error={errors} />;

  const page = data?.pageCollection?.items?.[0];
  if (!page) return <ErrorPage statusCode={404} />;

  return <PageHome {...page} />;
};

const ContextHomePage = (props: Props) => {
  const router = useRouter();
  const asPath = router.asPath;

  return (
    <ServerSideRenderingDataContext.Provider value={props.queryResults}>
      <Head>
        <title>HomePage</title>
        {generateCanonicalTag({ asPath })}
      </Head>
      <HomePage />
    </ServerSideRenderingDataContext.Provider>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async () => {
  const apolloClient = getApolloClientInstance();
  const ssrQueryMatrix = {
    layoutProps: { action: queryLayout(apolloClient), param: null },
    navigationProps: {
      action: querySiteNavigationModule(apolloClient),
      param: null
    },
    fourZeroFourProps: { action: query404Page(apolloClient), param: null },
    fourZeroFourBlocksProps: {
      action: queryBlocks(apolloClient),
      param: (fourZeroFourProps) =>
        fourZeroFourProps?.data?.pageCollection?.items?.[0]?.blocksCollection
          ?.items ?? []
    },
    homePageProps: {
      action: queryHomePage(apolloClient),
      param: null
    },
    pageBlocksProps: {
      action: queryBlocks(apolloClient),
      param: (homePageProps) =>
        homePageProps?.data?.pageCollection?.items[0]?.blocksCollection
          ?.items ?? []
    },
    pageBlockAccordions: {
      action: queryBlockAccordions(apolloClient),
      param: (pageBlocksProps) =>
        pageBlocksProps
          ?.filter(
            (block) => block.data?.block.__typename === 'BlockAccordions'
          )
          .map((block) => block.data.block.accordionsCollection.items)
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    }
  };

  const props = await ssrQueriesDispatch(ssrQueryMatrix);

  return {
    props: {
      queryResults: {
        PAGE_LAYOUT: props['layoutProps'],
        PAGE_NAVIGATION_MODULE_DATA: formatNavigationModule(
          props['navigationProps'].data.landingPageCollection.items[0]
            .navigationModule,
          props['pageProps']?.data.landingPageCollection.items[0]?.sys.id || ''
        ),
        PAGE_404: props['fourZeroFourProps'],
        HOMEPAGE_QUERY: props['homePageProps'],
        PAGE_BLOCKS: [
          ...props['fourZeroFourBlocksProps'],
          ...props['pageBlocksProps']
        ],
        BLOCK_ACCORDIONS: props['pageBlockAccordions']
      }
    }
  };
};

export default ContextHomePage;
