import Layout from '../Layout';
import Block from '../Block';
import React, { useEffect } from 'react';
import { ContentfulPageFragment } from '../../data/generated/graphql';
import { usePageTrackingContext } from '../../lib/context/PageTrackingContext';
import {
  GTMVirtualPageViewContentSource,
  GTMVirtualPageViewPageType,
  GTMVirtualPageViewSubType1
} from '../../helpers/useGaTracking';

const PageHome = (props: ContentfulPageFragment) => {
  const {
    title,
    metaDescription,
    shareImage,
    section,
    blocksCollection,
    sys
  } = props;
  const { updatePageContext } = usePageTrackingContext();

  const blocks = blocksCollection.items;
  useEffect(() => {
    updatePageContext({
      title: title,
      subType1: GTMVirtualPageViewSubType1.Detail,
      pageType: GTMVirtualPageViewPageType.Page,
      contentId: sys.id,
      contentSourceSystemId: GTMVirtualPageViewContentSource.Contentful
    });
  }, []);
  return (
    <Layout
      title={title}
      metaDescription={metaDescription}
      ogImage={shareImage && shareImage.url ? shareImage.url : null}
      section={section}
    >
      <div className="content-blocks">
        {blocks.map((block, key) => (
          <Block id={block.sys.id} typename={block.__typename} key={key} />
        ))}
      </div>
    </Layout>
  );
};

export default PageHome;
